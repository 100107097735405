<template>
  <b-card
    no-body
    class="shadow-body"
  >
    <b-card-body>
      <h5>{{ inputValues[1] ? inputValues[1].value : '' }}</h5>
      <p>{{ inputValues[2] ? inputValues[2].value : '' }}</p>
      <validation-observer
        ref="customFieldForm"
      >
        <b-row
          v-for="(opt, key) in fields"
          :key="key"
        >

          <b-col
            md="12"
          >
            <b-form-group
              v-if="opt.type == 'text'"
              :label="opt.label"
              :label-for="'event-custom-field-' + key"
              label-cols-md="4"
              class="pre-line-label"
            >
              <validation-provider
                #default="{ errors }"
                :name="opt.label"
                :vid="'event-custom-field-' + key"
              >
                <b-form-input
                  :id="'event-custom-field-' + key"
                  :placeholder="opt.placeholder"
                  :state="(errors.length > 0) ? false : null"
                />
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="opt.type == 'textarea'"
              :label="opt.label"
              :label-for="'event-custom-field-' + key"
              label-cols-md="4"
              class="pre-line-label"
            >
              <validation-provider
                #default="{ errors }"
                :name="opt.label"
                :vid="'event-custom-field-' + key"
              >
                <b-form-textarea
                  :id="'event-custom-field-' + key"
                  trim
                  :placeholder="opt.placeholder"
                  :state="(errors.length > 0) ? false : null"
                />
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="opt.type == 'radio-multiple'"
              label-cols-md="4"
              :label="opt.label"
              :label-for="'event-custom-field-' + key"
              class="pre-line-label"
            >
              <validation-provider
                #default="{ errors }"
                :name="opt.label"
              >

                <b-form-checkbox-group
                  :id="'event-custom-field-' + key"
                  buttons
                  button-variant="outline-primary"
                  class="spaceBetweenRadio display-block-checkbox"
                  :state="(errors.length > 0) ? false : null"
                >
                  <b-form-checkbox
                    v-for="(option, kindex) in opt.extraOptions"
                    :key="kindex"
                    :value="option.value"
                    class="mb-50 customCheckbox"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="mr-50"
                      size="16"
                    />
                    <span class="align-middle">{{ option.value }}</span>
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="opt.type == 'radio-single'"
              :label="opt.label"
              :label-for="'event-custom-field-' + key"
              label-cols-md="4"
              class="mb-margin-0 pre-line-label"
            >
              <validation-provider
                #default="{ errors }"
                :name="opt.label"
              >
                <b-form-checkbox-group
                  :id="'event-custom-field-' + key"
                  v-model="singleInputValues[key]"
                  buttons
                  button-variant="outline-primary"
                  class="spaceBetweenRadio display-block-checkbox"
                  :state="(errors.length > 0) ? false : null"
                  @input="makeCheckboxToRadio(key)"
                >
                  <b-form-checkbox
                    v-for="(option, kindex) in opt.extraOptions"
                    :key="kindex"
                    :value="option.value"
                    class="mb-50 customCheckbox"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="mr-50"
                      size="16"
                    />
                    <span class="align-middle">{{ option.value }}</span>
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="opt.type == 'block-select'"
              label-cols-md="4"
              :label="opt.label"
              :label-for="'event-custom-field-' + key"
              class="pre-line-label"
            >
              <validation-provider
                :name="opt.label"
              >

                <v-select
                  id="h-event-status"
                  label="title"
                  :placeholder="opt.placeholder"
                  :options="opt.selectOptions"
                  :reduce="title => title.code"
                  :clearable="false"
                />
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="opt.type == 'datasource-select'"
              label-cols-md="4"
              :label="opt.label"
              :label-for="'event-custom-field-' + key"
              class="pre-line-label"
            >
              <validation-provider
                :name="opt.label"
              >

                <v-select
                  id="h-event-status"
                  :placeholder="opt.placeholder"
                  :options="opt.selectOptions"
                  :clearable="false"
                />
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="opt.type == 'time-picker'"
              :label="opt.label"
              :label-for="'event-custom-field-' + key"
              label-cols-md="4"
              class="pre-line-label"
            >
              <b-input-group>
                <flat-pickr
                  class="form-control"
                  :config="{ wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true }"
                  value=""
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="ClockIcon"
                    class="cursor-pointer"
                    data-toggle
                    size="18"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group
              v-if="opt.type == 'date-picker'"
              :label="opt.label"
              :label-for="'event-custom-field-' + key"
              label-cols-md="4"
              class="pre-line-label"
            >
              <b-input-group>
                <flat-pickr
                  class="form-control"
                  :config="{ wrap: true, dateFormat: 'd/m/Y', disableMobile: true }"
                  value=""
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="CalendarIcon"
                    class="cursor-pointer"
                    data-toggle
                    size="18"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              v-if="opt.type == 'file'"
              :label="opt.label"
              :label-for="'event-custom-field-' + key"
              label-cols-md="4"
              class="pre-line-label"
            >
              <b-form-file
                :placeholder="opt.placeholder"
                :accept="(Array.isArray(opt.extraOptions[0].value) && opt.extraOptions[0].value.length) ? opt.extraOptions[0].value.toString() : ''"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
      >
        Save
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCard, BCardBody, BFormGroup, BFormInput, BButton, BFormTextarea, BFormCheckbox,
  BFormCheckboxGroup, BInputGroup, BInputGroupAppend, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,

    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    inputValues: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      statusDataOptions: [
        { title: 'Active', code: 'active' },
        { title: 'Inactive', code: 'inactive' },
      ],
      singleInputValues: [],
    }
  },
  watch: {
    fields: {
      immediate: true,
      handler(val, oldval) {
        if (val !== oldval) {
          val.forEach((opt, key) => {
            if (opt.type === 'block-select') {
              this.$http.post('operation/work-flows/task/find-block', { block: opt.extraOptions[0].value })
                .then(response => {
                  const taskBlock = response.data.data.taskBlocks.find(element => element.fieldValues[0].value === opt.extraOptions[0].value)
                  const optionArray = []
                  taskBlock.block.fields.forEach(arrayItem => {
                    if (arrayItem.type === 'text') {
                      const newObj = {}
                      newObj.code = arrayItem.label
                      newObj.title = arrayItem.label
                      optionArray.push(newObj)
                    }
                  })

                  // eslint-disable-next-line no-param-reassign
                  opt.selectOptions = optionArray
                  this.$set(this.fields, key, opt)
                })
                .catch(error => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }

            if (opt.type === 'datasource-select') {
              this.$http.post('operation/work-flows/task/data-source', { source: opt.extraOptions[0].value })
                .then(response => {
                  // eslint-disable-next-line no-param-reassign
                  opt.selectOptions = response.data.data
                  this.$set(this.fields, key, opt)
                })
                .catch(error => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
        }
      },
    },
  },
  methods: {
    makeCheckboxToRadio(key) {
      const lastSelected = this.singleInputValues[key].slice(-1)[0]
      if (lastSelected) {
        this.singleInputValues[key] = this.singleInputValues[key].filter(code => code === lastSelected)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
</style>
