<template>
  <b-card
    no-body
    class="shadow-body"
  >
    <b-card-body>
      <h5>{{ inputValues[1] ? inputValues[1].value : '' }}</h5>
      <p>{{ inputValues[2] ? inputValues[2].value : '' }}</p>
      <div
        v-if="inputValues[3] && inputValues[3].value == 'user can enter'"
      >
        <b-form-group
          label="To: "
          label-for="sms-to"
          label-cols-md="1"
        >
          <b-form-input
            id="sms-to"
            placeholder="Chat ID (Separate with comma if multiple)"
          />
        </b-form-group>
      </div><div
        v-else
      >
        <p>
          To: {{ resolveEmailValue() }}
        </p>
      </div>
      <b-form-textarea
        v-model="inputValues[5].value"
        trim
        :placeholder="inputValues[4] ? inputValues[4].value : ''"
      />
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-2"
      >
        Send Message
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BButton, BFormTextarea, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardBody,
    BButton,
    BFormTextarea,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    inputValues: {
      type: Array,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
  },
  methods: {
    resolveEmailValue() {
      let phone = ''
      if (this.inputValues[3] && this.inputValues[3].value === 'pre-defined recipient') {
        let list = ''
        this.customFields[0].value.forEach((arrayItem, index) => {
          if ((index + 1) < this.customFields[0].value.length) {
            list += `${arrayItem}, `
          } else {
            list += arrayItem
          }
        })
        phone = list
      } else if (this.inputValues[3] && this.inputValues[3].value === 'data-source') {
        if (this.customFields[0].value === 'Service Form') {
          if (this.customFields[1].value === 'Agent') {
            phone = '88889999'
          } else {
            phone = '88889999'
          }
        } else {
          phone = ''
        }
      } else if (this.inputValues[3] && this.inputValues[3].value === 'another block') {
        phone = '88889999'
      }
      return phone
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
