<template>
  <b-card
    no-body
    class="shadow-body"
  >
    <b-card-body>
      <h5>{{ inputValues[1] ? inputValues[1].value : '' }}</h5>
      <p>{{ inputValues[2] ? inputValues[2].value : '' }}</p>
      <!-- <p>To: {{ inputValues[3] ? inputValues[3].value : '' }}</p> -->
      <div
        v-if="inputValues[3] && inputValues[3].value == 'user can enter'"
      >
        <b-form-group
          label="To: "
          label-for="email-to"
          label-cols-md="1"
        >
          <b-form-input
            id="email-to"
            placeholder="Email"
          />
        </b-form-group>
      </div>
      <div
        v-else
      >
        <p>
          To: {{ resolveEmailValue() }}
        </p>
      </div>
      <hr class="dividerHR">
      <!-- <p>Subject: {{ inputValues[4] ? inputValues[4].value : '' }}</p> -->
      <div
        v-if="inputValues[4]"
      >
        <b-form-group
          label="Subject: "
          label-for="email-subject"
          label-cols-md="1"
        >
          <b-form-input
            id="email-subject"
            placeholder="Subject"
            :value="inputValues[4].value"
          />
        </b-form-group>
      </div>
      <hr class="dividerHR">
      <!-- eslint-disable vue/no-v-html -->
      <!-- <div v-html="inputValues[5] ? inputValues[5].value : ''" /> -->
      <!--eslint-enable-->
      <quill-editor
        :options="editorOption"
      >
        <div
          :id="'toolbarPreview-'+randID"
          slot="toolbar"
        >
          <!-- Add a bold button -->
          <button class="ql-bold">
            Bold
          </button>
          <button class="ql-italic">
            Italic
          </button>
          <button class="ql-underline">
            Underline
          </button>
          <select class="ql-size">
            <option value="small" />
            <!-- Note a missing, thus falsy value, is used to reset to default -->
            <option selected />
            <option value="large" />
            <option value="huge" />
          </select>

          <select class="ql-align">
            <option selected="selected" />
            <option value="center" />
            <option value="right" />
            <option value="justify" />
          </select>
        </div>
      </quill-editor>
      <b-row>
        <b-col cols="md-4">
          <p>Attachment</p>
        </b-col>
        <b-col cols="md-8">
          <b-form-file :accept="inputValues[6] ? inputValues[6].value.toString() : ''" />
        </b-col>
      </b-row>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-2"
      >
        Send
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BFormGroup, BFormInput, BFormFile, BButton, BRow, BCol,
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardBody,
    BFormFile,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,

    quillEditor,
  },
  directives: {
    Ripple,
  },
  props: {
    inputValues: {
      type: Array,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    const randID = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10)
    return {
      randID,
      editorOption: {
        modules: {
          toolbar: `#toolbarPreview-${randID}`,
        },
        placeholder: 'Compose message',
      },
    }
  },
  methods: {
    resolveEmailValue() {
      let email = ''
      if (this.inputValues[3] && this.inputValues[3].value === 'pre-defined recipient') {
        let list = ''
        this.customFields[0].value.forEach((arrayItem, index) => {
          if ((index + 1) < this.customFields[0].value.length) {
            list += `${arrayItem}, `
          } else {
            list += arrayItem
          }
        })
        email = list
      } else if (this.inputValues[3] && this.inputValues[3].value === 'data-source') {
        if (this.customFields[0].value === 'Service Form') {
          if (this.customFields[1].value === 'Agent') {
            email = ''
          } else {
            email = 'customer@mail.com'
          }
        } else {
          email = 'user@nirvana.com.sg'
        }
      } else if (this.inputValues[3] && this.inputValues[3].value === 'another block') {
        email = 'something@mail.com'
      }
      return email
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
