<template>
  <b-card
    no-body
    class="shadow-body"
  >
    <b-card-body>
      <h5>{{ inputValues[1] ? inputValues[1].value : '' }}</h5>
      <p>{{ inputValues[2] ? inputValues[2].value : '' }}</p>
      <b-row
        v-if="!isMobile()"
        style="margin-bottom: 6px"
      >
        <b-col
          lg="4"
          md="4"
          sm="12"
          class="mt-auto"
        >Item(s)
        </b-col>
        <b-col
          lg="3"
          md="3"
          sm="12"
          class="mt-auto"
        >Store</b-col>
        <b-col
          lg="2"
          md="2"
          sm="12"
          class="px-25"
        >
          <div v-if="inputValues[3] && inputValues[3].value == 2">
            <b-row>
              <b-col
                lg="6"
                md="6"
                sm="12"
                class="pr-0"
              >
                <span style="font-size: 12px; color: #999999;">
                  NMG
                </span>
                <br>
                <span>
                  Quantity
                </span>
              </b-col>
              <b-col
                lg="6"
                md="6"
                sm="12"
                class="pl-25"
              >
                <span style="font-size: 12px; color: #999999;">
                  MPL
                </span>
                <br>
                <span>
                  Quantity
                </span>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <span>
              Quantity
            </span>
          </div>
        </b-col>
        <b-col
          lg="2"
          md="2"
          sm="12"
          class="mt-auto"
        >
          Item File No.
          <feather-icon
            id="fileNoTooltip"
            icon="AlertCircleIcon"
            class="ml-50 text-primary"
            size="14"
          />
          <b-tooltip
            placement="auto"
            target="fileNoTooltip"
            title="This is an optional field to add urn file no."
          />
        </b-col>
        <b-col
          lg="1"
          md="1"
          sm="12"
          class="mt-auto"
        />
      </b-row>
      <div v-if="stockItems.length">
        <b-row
          v-for="(stockItem, index) in stockItems"
          :id="'stockItems' + index"
          :key="index"
        >
          <b-col
            lg="4"
            md="4"
            sm="12"
          >
            <span v-if="isMobile()">Item(s)</span>
            <div
              class="d-flex justify-content-between search-bar"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                vid="name"
                rules="required"
                style="width: 100%"
              >
                <b-form-group
                  :label-for="'itemName'+ index"
                  :class="{ 'is-invalid': errors.length }"
                >
                  <b-form-input
                    :id="'itemName'+ index"
                    v-model="stockItem.name"
                    name="name"
                    :state="(errors.length > 0) ? false : null"
                    placeholder="Item name"
                    disabled
                    class="disabled-clear"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <b-img
                class="item-img-block"
                :src="stockItem.image ? stockItem.image : defaultImage"
              />
            </div>
          </b-col>
          <b-col
            lg="3"
            md="3"
            sm="12"
          >
            <span
              v-if="isMobile()"
              class="mt-50 d-block"
            >
              Store
            </span>
            <!-- <v-select
              v-model="store"
              label="name"
              placeholder="Select a store"
              :options="storeDataOptions"
              :reduce="name => name._id"
              :clearable="false"
            >
              <template #option="{ name, department }">
                <span style="font-size: 14px;font-weight: 400;"> {{ name }}</span>
                <br>
                <span
                  style="font-weight: 500;font-size: 12px;"
                  class="text-muted"
                >
                  {{ department.name }}
                </span>
              </template>
            </v-select> -->
            <validation-provider
              #default="{ errors }"
              name="Store"
              :vid="`store_${index}`"
              rules="required"
            >
              <b-form-group
                :label-for="`store_${index}`"
                class="mb-0"
                :class="{ 'is-invalid': errors.length }"
              >
                <v-select
                  :id="`store_${index}`"
                  v-model="stockItem.store"
                  label="name"
                  placeholder="Select a store"
                  disabled
                  class="disabled-clear"
                  :options="storeDataOptionComputation(stockItem.item)"
                  :reduce="name => name._id"
                  :clearable="false"
                  @input="stockStoreValidation(index, storeIndex, stockItem.item, stockItem.store)"
                >
                  <template #option="{ name, department }">
                    <span style="font-size: 14px;font-weight: 400;"> {{ name }}</span>
                    <br>
                    <span
                      style="font-weight: 500;font-size: 12px;"
                      class="text-muted"
                    >
                      {{ department.name }}
                    </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            lg="2"
            md="2"
            sm="12"
            class="px-25"
          >
            <div v-if="inputValues[3] && inputValues[3].value == 2">
              <b-row>
                <b-col
                  lg="6"
                  md="6"
                  sm="12"
                  class="pr-0"
                >
                  <span
                    v-if="isMobile()"
                    class="mt-50 d-block"
                    style="font-size: 12px; color: #999999;"
                  >
                    NMG
                  </span>
                  <br v-if="isMobile()">
                  <span
                    v-if="isMobile()"
                    class="mt-50 d-block"
                  >
                    Quantity
                  </span>
                  <!-- <b-form-input
                    id="search_quantity"
                    v-model="quantity[0]"
                    name="quantity"
                    placeholder="0"
                    @keypress="validateNumberInput"
                    @paste="validateNumberPaste"
                  /> -->
                  <validation-provider
                    #default="{ errors }"
                    name="Quantity"
                    :vid="'quantity1'+ index"
                    rules="required"
                    style="width: 100%"
                  >
                    <b-form-group
                      :label-for="'quantity1'+ index"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <b-form-input
                        :id="'quantity1'+ index"
                        v-model="stockItem.quantity[0]"
                        name="quantity"
                        :state="(errors.length > 0) ? false : null"
                        placeholder="0"
                        @keypress="validateNumberInput"
                        @paste="validateNumberPaste"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  lg="6"
                  md="6"
                  sm="12"
                  class="pl-25"
                >
                  <span
                    v-if="isMobile()"
                    class="mt-50 d-block"
                    style="font-size: 12px; color: #999999;"
                  >
                    MPL
                  </span>
                  <br v-if="isMobile()">
                  <span
                    v-if="isMobile()"
                    class="mt-50 d-block"
                  >
                    Quantity
                  </span>
                  <validation-provider
                    #default="{ errors }"
                    name="Quantity"
                    :vid="'quantity2'+ index"
                    rules="required"
                    style="width: 100%"
                  >
                    <b-form-group
                      :label-for="'quantity2'+ index"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <b-form-input
                        :id="'quantity2'+ index"
                        v-model="stockItem.quantity[1]"
                        name="quantity"
                        :state="(errors.length > 0) ? false : null"
                        placeholder="0"
                        @keypress="validateNumberInput"
                        @paste="validateNumberPaste"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <span
                v-if="isMobile()"
                class="mt-50 d-block"
              >
                Quantity
              </span>
              <validation-provider
                #default="{ errors }"
                name="Quantity"
                :vid="'quantity1'+ index"
                rules="required"
                style="width: 100%"
              >
                <b-form-group
                  :label-for="'quantity1'+ index"
                  :class="{ 'is-invalid': errors.length }"
                >
                  <b-form-input
                    :id="'quantity1'+ index"
                    v-model="stockItem.quantity[0]"
                    name="quantity"
                    :state="(errors.length > 0) ? false : null"
                    placeholder="0"
                    @keypress="validateNumberInput"
                    @paste="validateNumberPaste"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </div>
          </b-col>
          <b-col
            lg="2"
            md="2"
            sm="12"
          >
            <span
              v-if="isMobile()"
              class="mt-50 d-block"
            >
              Item File No.
            </span>
            <validation-provider
              #default="{ errors }"
              name="File No"
              :vid="'fileNo'+ index"
              rules=""
              style="width: 100%"
            >
              <b-form-group
                :label-for="'fileNo'+ index"
                :class="{ 'is-invalid': errors.length }"
              >
                <b-form-input
                  :id="'fileNo'+ index"
                  v-model="stockItem.fileNo"
                  name="fileNo"
                  :state="(errors.length > 0) ? false : null"
                  placeholder="Eg. 68A013401"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="1"
            lg="1"
            sm="12"
            :class="{'p-0':!isMobile()}"
          >
            <b-button
              variant="flat-primary"
              class="pl-0 primary-button-with-background no-background"
              type="button"
              @click="removeStockItem(index)"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-25 trash-icon"
              />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col
          lg="4"
          md="4"
          sm="12"
        >
          <span v-if="isMobile()">Item(s)</span>
          <div
            class="d-flex justify-content-between search-bar"
          >
            <b-form-input
              id="search_name"
              v-model="searchInput"
              label="Item(s)"
              name="name"
              placeholder="Search item"
              autocomplete="off"
              @keyup="submitSearchInput()"
            />
            <b-img
              class="item-img-block"
              :src="defaultImage"
            />
          </div>
          <div v-show="searchInputResult">
            <div
              class="search-results mt-50"
            >
              <div>
                <b-list-group
                  v-if="storeItemSearchInputData.length"
                  flush
                >
                  <span class="found-text-block">Inventory items found in this store</span>
                  <b-list-group-item
                    v-for="(storeItem, sIndex) in storeItemSearchInputData"
                    :key="sIndex"
                    class="cursor-pointer"
                    @click="addSearchInputStoreItem(sIndex, storeItem.item._id)"
                  >
                    <div class="d-flex">
                      <b-img
                        class="item-img-block"
                        :src="storeItem.item.image ? storeItem.item.image : defaultImage"
                      />
                      <div
                        class="search-item-text-block"
                      >
                        <h6>{{ storeItem.item.name }}</h6>
                        <small class="text-muted">{{ storeItem.item.sku }}</small>
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
          </div>
          <b-card
            v-show="searchResultNotFound"
            no-body
            class="mt-50"
          >
            <div class="result-not-found-block">
              <h5>No inventory item found.</h5>
            </div>
          </b-card>
        </b-col>
        <b-col
          lg="3"
          md="3"
          sm="12"
        >
          <span
            v-if="isMobile()"
            class="mt-50 d-block"
          >
            Store
          </span>
          <v-select
            id="search_store"
            v-model="store"
            label="name"
            placeholder="Select a store"
            :options="storeDataOptions"
            :reduce="name => name._id"
            :clearable="false"
          >
            <template #option="{ name, department }">
              <span style="font-size: 14px;font-weight: 400;"> {{ name }}</span>
              <br>
              <span
                style="font-weight: 500;font-size: 12px;"
                class="text-muted"
              >
                {{ department.name }}
              </span>
            </template>
          </v-select>
        </b-col>
        <b-col
          lg="2"
          md="2"
          sm="12"
          class="px-25"
        >
          <div v-if="inputValues[3] && inputValues[3].value == 2">
            <b-row>
              <b-col
                lg="6"
                md="6"
                sm="12"
                class="pr-0"
              >
                <span
                  v-if="isMobile()"
                  class="mt-50 d-block"
                  style="font-size: 12px; color: #999999;"
                >
                  NMG
                </span>
                <br v-if="isMobile()">
                <span
                  v-if="isMobile()"
                  class="mt-50 d-block"
                >
                  Quantity
                </span>
                <b-form-input
                  id="search_quantity"
                  v-model="quantity[0]"
                  name="quantity"
                  placeholder="0"
                  @keypress="validateNumberInput"
                  @paste="validateNumberPaste"
                />
              </b-col>
              <b-col
                lg="6"
                md="6"
                sm="12"
                class="pl-25"
              >
                <span
                  v-if="isMobile()"
                  class="mt-50 d-block"
                  style="font-size: 12px; color: #999999;"
                >
                  MPL
                </span>
                <br v-if="isMobile()">
                <span
                  v-if="isMobile()"
                  class="mt-50 d-block"
                >
                  Quantity
                </span>
                <b-form-input
                  id="search_quantity"
                  v-model="quantity[1]"
                  name="quantity"
                  placeholder="0"
                  @keypress="validateNumberInput"
                  @paste="validateNumberPaste"
                />
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <span
              v-if="isMobile()"
              class="mt-50 d-block"
            >
              Quantity
            </span>
            <b-form-input
              id="search_quantity"
              v-model="quantity[0]"
              name="quantity"
              placeholder="0"
              @keypress="validateNumberInput"
              @paste="validateNumberPaste"
            />
          </div>
        </b-col>
        <b-col
          lg="2"
          md="2"
          sm="12"
        >
          <span
            v-if="isMobile()"
            class="mt-50 d-block"
          >
            Item File No.
          </span>
          <b-form-input
            id="search_fileNO"
            v-model="fileNo"
            name="fileNo"
            placeholder="Eg. 68A013401"
          />
        </b-col>
        <b-col
          lg="1"
          md="1"
          sm="0"
        />
      </b-row>
      <div class="d-flex justify-content-between mt-2">
        <p><span class="text-bold-black mr-75">Inventory Item(s)</span>  {{ stockItems.length ? stockItems.length : '-' }}</p>
        <p><span class="text-bold-black">Total Quantity</span> <span class="ml-75">{{ stockOutTotalQuantity }}</span></p>
      </div>
      <hr>
      <b-form-group
        label="Add Remarks"
        label-for="remarks"
        label-cols="2"
      >
        <b-form-textarea
          id="remarks"
          v-model="remarks"
          name="remarks"
          rows="5"
          placeholder="Optional"
        />
      </b-form-group>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-2"
      >
        Generate Inventory Request
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BCard, BCardBody, BButton, BFormTextarea, BFormGroup, BFormInput, BRow, BCol, BImg, VBTooltip, BTooltip, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BButton,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BImg,
    BTooltip,
    BListGroup,
    BListGroupItem,
    vSelect,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: { 'b-tooltip': VBTooltip, Ripple },
  props: {
    inputValues: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      saveStockOut: false,
      searchInput: '',
      storeOriginal: '',
      store: '',
      user: store.state.auth.userData,
      quantity: [],
      fileNo: '',
      remarks: '',
      remarksError: 'Valid Remarks is required',
      remarksValidation: false,
      storeDataOptions: [],
      stockItems: [],
      storeItemData: [],
      selectStockItem: [],
      storeItemSearchInputData: [],
      searchInputResult: false,
      searchResultNotFound: false,
      stockOutPreviewItems: [],
    }
  },
  computed: {
    stockOutTotalQuantity() {
      const allStockItems = this.stockItems.length
      if (allStockItems) {
        let initStockQuantity = 0
        this.stockItems.forEach(item => {
          if (item.quantity.length) {
            // eslint-disable-next-line eqeqeq
            if (this.inputValues[3] && this.inputValues[3].value == 2) {
              initStockQuantity -= -item.quantity.reduce((a, b) => a - (-b), 0)
            } else {
              initStockQuantity -= -item.quantity[0]
            }
          }
        })
        return initStockQuantity
      }
      return 0
    },
  },
  created() {
    this.$http.get('inventory/stock/respond-with/store-and-item')
      .then(response => {
        this.storeDataOptions = response.data.stores
        this.storeItemData = response.data.storeItems
        this.store = this.storeDataOptions[0]._id
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    storeDataOptionComputation(stockItemId) {
      const a = this.storeDataOptions.filter(obj => {
        const found = this.storeItemData.find(sData => sData.item._id === stockItemId && sData.store === obj._id)
        if (found) {
          return true
        }
        return false
      })

      return a
    },
    addMoreStore(index) {
      this.stockItems[index].stores.push({
        store: '',
        quantity: '',
      })
    },
    removeStoreData(index, storeIndex) {
      this.stockItems[index].stores.splice(storeIndex, 1)
    },
    removeStockItem(stockIndex) {
      this.stockItems.splice(stockIndex, 1)
    },
    submitSearchInput() {
      if (this.inputValues[5] && (this.inputValues[5].value === 'all' || this.inputValues[5].value === '')) {
        this.storeItemSearchInputData = this.storeItemData.filter(item => item.store === this.store && item.item.deletedAt === null && (item.itemName.toLowerCase().includes(this.searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.searchInput.toLowerCase())))
      } else if (this.inputValues[5] && (this.inputValues[5].value !== 'all' || this.inputValues[5].value !== '')) {
        this.storeItemSearchInputData = this.storeItemData.filter(item => item.store === this.store && item.category === this.inputValues[5].value && item.item.deletedAt === null && (item.itemName.toLowerCase().includes(this.searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.searchInput.toLowerCase())))
      }
      // this.storeItemSearchInputData = this.storeItemData.filter(item => item.store === this.store && item.item.deletedAt === null && (item.itemName.toLowerCase().includes(this.searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.searchInput.toLowerCase())))
      if (this.searchInput !== '') {
        this.searchInputResult = true
      } else {
        this.searchInputResult = false
      }
      if (!this.storeItemSearchInputData.length) {
        this.searchResultNotFound = true
      } else {
        this.searchResultNotFound = false
      }
    },
    validateNumberInput(event) {
      if (event.key && !/^-?\d*[.]?\d*$/.test(event.key)) {
        event.preventDefault()
      }
    },
    validateNumberPaste(event) {
      const text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (text && !/^-?\d*[.]?\d*$/.test(text)) {
        event.preventDefault()
      }
    },
    addSearchInputStoreItem(index, itemId) {
      if (this.storeItemData.length) {
        const item = this.storeItemData.find(storeItemObj => storeItemObj.item._id === itemId && storeItemObj.store === this.store)
        if (item) {
          if (this.stockItems.length) {
            const foundItemId = this.stockItems.find(obj => obj.item === item.item._id && obj.store === this.store)
            if (foundItemId) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'This item is already added!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.stockItems.push({
                item: item.item._id,
                name: item.item.name,
                image: item.item.image,
                sku: item.item.sku,
                store: this.store,
                quantity: [],
                fileNo: '',
                // stores: [{
                //   store: this.store,
                //   quantity: this.quantity,
                // }],
              })
              this.searchInput = ''
              this.searchInputResult = false
              this.storeItemSearchInputData.splice(index, 1)
            }
          } else {
            this.stockItems.push({
              item: item.item._id,
              name: item.item.name,
              image: item.item.image,
              sku: item.item.sku,
              store: this.store,
              quantity: [],
              fileNo: '',
              // stores: [{
              //   store: this.store,
              //   quantity: this.quantity,
              // }],
            })
            this.searchInput = ''
            this.searchInputResult = false
            this.storeItemSearchInputData.splice(index, 1)
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'This item is not found on this store.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    stockStoreValidation(stockItemIndex, storeIndex, stockItemId, storeId) {
      const storeFound = this.storeItemData.find(sData => sData.item._id === stockItemId && sData.store === storeId)
      const selectedStoreFound = this.stockItems[stockItemIndex].stores.filter(obj => obj.store === storeId)
      if (selectedStoreFound.length > 1) {
        this.stockItems[stockItemIndex].stores[storeIndex].store = ''
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This store is already selected in this selected item, please select another store.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      } else {
        this.stockItems[stockItemIndex].stores[storeIndex].store = storeId
      }
      if (!storeFound) {
        this.stockItems[stockItemIndex].stores[storeIndex].store = ''
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Selected inventory item was not found for the selected store!',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
    },
    stockQuantityValidation(stockItemIndex, storeIndex, stockItemId, storeId, quantity) {
      const storeFound = this.storeItemData.find(sData => sData.item._id === stockItemId && sData.store === storeId)
      if (storeFound) {
        if (quantity > storeFound.stock) {
          this.stockItems[stockItemIndex].stores[storeIndex].quantity = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Stock not available more than ${storeFound.stock} in this item!`,
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          })
        }
      }
    },
    closeStockOutItemsModal() {
      // this.$root.$emit('bv::hide::modal', 'modal-stock-out-items', '')
      this.stockOutPreviewItems = []
    },
    resolveStoreName(storeId) {
      const storeData = this.storeDataOptions.find(s => s._id === storeId)
      if (storeData) {
        return storeData.name
      }
      return ''
    },
    stockOutAllItemsPreview() {
      this.stockOutPreviewItems = []
      this.stockItems.forEach(stockItem => {
        stockItem.stores.forEach(storeData => {
          this.stockOutPreviewItems.push({
            item: stockItem.item,
            name: stockItem.name,
            image: stockItem.image,
            sku: stockItem.sku,
            store: storeData.store,
            quantity: storeData.quantity,
          })
        })
      })
    },
    submitStockOutForm() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submitted',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
