<template>
  <b-card
    no-body
    class="shadow-body"
  >
    <b-card-body>
      <h5>{{ inputValues[1] ? inputValues[1].value : '' }}</h5>
      <p>{{ inputValues[2] ? inputValues[2].value : '' }}</p>
      <vue-dropzone
        id="event-images"
        ref="myVueDropzone"
        :options="dropzoneOptions"
        :use-custom-slot="true"
        @vdropzone-files-added="processFile"
      >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">
            Drop file here or click to upload
          </h3>
          <div class="subtitle">
            Maximum upload file size : 5MB
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
            class="mt-2 no-float"
          >
            Upload file
          </b-button>
        </div>
      </vue-dropzone>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-2"
      >
        Upload
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BButton,

    vueDropzone: vue2Dropzone,
  },
  directives: {
    Ripple,
  },
  props: {
    inputValues: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        // acceptedFiles: 'image/jpeg',
        maxFilesize: 5,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
        // maxFiles: 1,
      },
    }
  },
  watch: {
    inputValues: {
      immediate: true,
      handler(val) {
        // this.dropzoneOptions = {
        //   autoProcessQueue: false,
        //   url: 'https://httpbin.org/post',
        //   acceptedFiles: val[3] ? val[3].value.toString() : '',
        //   maxFilesize: 5,
        //   addRemoveLinks: true,
        //   // maxFiles: 1,
        // }
        if (this.$refs.myVueDropzone) {
          this.$refs.myVueDropzone.setOption('acceptedFiles', val[3] ? val[3].value.toString() : null)
          // this.$refs.myVueDropzone.options.maxFilesize = val[3] ? val[3].value.toString() : null
          // this.$refs.myVueDropzone.dropzone.options.acceptedFiles = val[3] ? val[3].value.toString() : null
        }
      },
      deep: true,
    },
  },
  methods: {
    processFile(file) {
      const acceptedTypes = this.inputValues[3].value
      file.forEach(element => {
        if (element.size > 5 * 1024 * 1024) {
          this.$refs.myVueDropzone.removeFile(element)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Image is too large',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } else if (acceptedTypes.length && !acceptedTypes.includes(element.type)) {
          this.$refs.myVueDropzone.removeFile(element)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unsupported file type',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
